
import {ref, onMounted} from 'vue';
import {useRoute} from 'vue-router';

import Card from 'primevue/card';
import Button from 'primevue/button';

import {authModule} from '@/store/modules/auth';
import {SERVER_ERRORS} from '@/constants/errors';

export default {
  components: {
    Card, Button
  },
  setup() {
    const token = ref('');
    const message = ref('Подтверждаем Ваш email...');

    const route = useRoute();

    onMounted(async () => {
      if (route.query.token) {
        token.value = route.query.token.toString();
        try {
          await authModule.actions.verify({token: token.value});
          message.value = `Ваш адрес электронной почты подтвержден. Спасибо.`;
        } catch (error) {
          message.value = SERVER_ERRORS[error.message || '999'];
        }
      }
    });

    return {
      message,
    }
  }
}
